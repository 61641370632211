<template>
  <navigation-bar v-bind:value="navs"/>
  <router-view/>
  <site-footer v-bind:value="navs"></site-footer>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import SiteFooter from "@/components/SiteFooter";

export default {
  name: 'App',
  data(){
    return {
      navs:[
        {name:'解决方案',url:'/'},
        {name:'产品优势',url:'/advantage'},
        {name:'客户案例',url:'/customer'},
        {name:'合作伙伴',url:'/problem'},
        {name:'关于企业',url:'/about'},
      ]
    }
  },
  components: {
    NavigationBar,
    SiteFooter
  }
}
</script>

<style lang="scss">
</style>
