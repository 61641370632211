<template>
  <footer id="site-footer" :style="{background:'url('+require('../assets/img/background/bg-footer.png')+') no-repeat 0 0',backgroundSize: 'cover'}">
    <div class="wrapper">
      <div class="qrcode">
        <i class="icon-qrcode"></i>
      </div>
      <div class="content">
        <nav>
          <router-link v-for="(n,i) in value" :key="i" :to="n.url">{{ n.name }}</router-link>
        </nav>
        <p class="contact">
          <i class="icon-tel"></i>
          <label>0411-84519967</label>
        </p>
        <p class="contact">
          <i class="icon-fax"></i>
          <label>0411-84519957</label>
        </p>
        <p class="contact_phone">
          <i class="icon-tel"></i>
          <label>0411-84519967</label>
          <i class="icon-fax"></i>
          <label>0411-84519957</label>
        </p>
        <p class="address">
          <label class="l">Copyright &copy; 2017-2021 大连高德瑞信科技有限公司 辽ICP备案18009213号-1</label>
          <label class="r">辽宁省大连高新技术产业园区广贤路133号2301</label>
        </p>
        <p class="address_phone">
          <label class="r">辽宁省大连高新技术产业园区广贤路133号2301</label>
          <label class="l">Copyright &copy; 2017-2021 大连高德瑞信科技有限公司 辽ICP备案18009213号-1</label>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  props:{
    value: [Object]
  },
  data(){
    return {
      /*domain:window._DOMAIN*/
    }
  }
}
</script>

<style scoped>
  #site-footer{
    background-color: #242f59;
  }
</style>
