<template>
  <div id="NavigationBar">
    <div class="wrapper flex">
      <a class="site-home">
        <i class="icon-logo"></i>
        <span class="site-name">大连高德瑞信科技有限公司</span>
      </a>
      <nav>
        <router-link v-for="(n,i) in value" :key="i" :to="n.url">{{ n.name }}</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props:{
    value: [Object]
  },
  mounted() {
    console.log()
  }
}
</script>

<style lang="scss" scoped>

</style>
