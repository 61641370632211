import { createRouter,createWebHistory } from 'vue-router'


const routes = [
  {path: '/', name: 'Index', component: () => import('../views/Index.vue')},
  {path: '/advantage', name: 'Advantage', component: () => import('../views/Advantage.vue')},
  {path: '/customer', name: 'Customer', component: () => import('../views/Customer.vue')},
  {path: '/problem', name: 'Problem', component: () => import('../views/Problem.vue')},
  {path: '/about', name: 'About', component: () => import('../views/About.vue')},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
